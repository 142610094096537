<template>
  <div class="apartment-list ">
    <div class="apartment-list-header">
      <div class="search-box por">
        <apartment-search
          :searchText="langText.search"
          :value="keyword"
          @search="search"
        ></apartment-search>
      </div>
      <!-- 筛选 -->
      <div class="filter-box-wrap dfr cross-center main-between fs14">
        <div class="filter-left dfr cross-center">
          <!-- price -->
          <div @click="showPrice" class="filter-box pointer por">
            <el-input
              v-model.trim="minPrice"
              :placeholder="langText.filter.minPrice"
              @keyup.enter.native="initPageNumAndList"
              @blur="initPageNumAndList"
              @clear="initPageNumAndList"
              clearable
            ></el-input>
          </div>

          <div @click="showPrice" class="filter-box pointer por">
            <el-input
              v-model.trim="maxPrice"
              :placeholder="langText.filter.maxPrice"
              @keyup.enter.native="initPageNumAndList"
              @blur="initPageNumAndList"
              @clear="initPageNumAndList"
              clearable
            ></el-input>
          </div>

          <!-- beds -->
          <div class="filter-box">
            <el-select
              class="filter-item"
              v-model="currentBeds"
              filterable
              :placeholder="langText.filter.beds"
              clearable
              @change="initPageNumAndList"
              @clear="initPageNumAndList"
            >
              <el-option
                v-for="item in beds"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- date -->
          <div class="filter-box">
            <!-- <el-date-picker
              class="filter-item"
              v-model="moveDate"
              type="date"
              :placeholder="langText.filter.distance"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="dateChange"
              @clear="dateChange"
            >
            </el-date-picker> -->
            <div
              @click="distanceSortHandle"
              class="distance-box dfr cross-center pointer"
            >
              {{ langText.filter.distance }}
              <div class="icon-box">
                <!-- 1升序  ,  2降序 -->
                <img
                  v-if="distanceSort == 1"
                  class="distance-l-1 distance-icon"
                  src="../assets/images/arrow-down.png"
                  alt=""
                />
                <img
                  v-if="distanceSort == 2"
                  class="distance-r-2 distance-icon"
                  src="../assets/images/arrow-up.png"
                  alt=""
                />

                <img
                  v-if="distanceSort == 1"
                  class="distance-r-1 distance-icon"
                  src="../assets/images/arrow-up.png"
                  alt=""
                />
                <img
                  v-if="distanceSort == 2"
                  class="distance-l-2 distance-icon"
                  src="../assets/images/arrow-down.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="filter-right mhide dfr cross-center">
          <!-- show map -->
          <div class="fs16 fc666">{{ langText.filter.showMap }}</div>

          <el-switch
            class="filter-switch "
            v-model="unShowMap"
            active-color="#CCCCCC"
            inactive-color=" #87BCDC"
            :width="32"
            @change="unShowMapChange"
          >
          </el-switch>
          <!-- <i class="el-icon-check"></i> -->
        </div>
      </div>
    </div>
    <div class="apartment-content ">
      <div
        :class="[
          unShowMap ? 'hide-map-wrap' : 'show-map-wrap',
          'apartment-map-wrap'
        ]"
      >
        <div v-if="apartmentList.length" class="content-list ">
          <div
            v-for="item in apartmentList"
            :key="item.id"
            class="content-item"
            @mouseover="mouseOver(item)"
          >
            <apartment-card
              :unShowMap="unShowMap"
              :info="item"
              :lang="lang"
            ></apartment-card>
          </div>
        </div>
        <div v-if="!apartmentList.length && isEmpty">
          <span v-if="lang == 'CN'">暂无数据</span>
          <span v-else>No data available</span>
        </div>
        <div
          :class="[unShowMap ? 'hide-map-wrap' : 'show-map-wrap']"
          class="map"
        >
          <div id="map_canvas" class="w100 h100"></div>
        </div>
      </div>
      <div class="dfr flex-center">
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="page"
          @current-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import MarkerWithLabel from "@googlemaps/markerwithlabel";
// import { getPosition } from "../utils/util";
export default {
  data() {
    return {
      beds: [
        {
          value: "1",
          label: "1 Bedroom"
        },
        {
          value: "2",
          label: "2 Bedroom"
        },
        {
          value: "3",
          label: "3 Bedroom"
        },
        {
          value: "4",
          label: "4 Bedroom"
        },
        {
          value: "5",
          label: "5 Bedroom"
        }
      ],
      page: 1,
      pageSize: 6,
      minPrice: "",
      maxPrice: "",
      currentBeds: "",
      currentType: "",
      moveDate: "",
      currentMore: "",
      unShowMap: true,
      total: 0,
      apartmentList: [],
      isEmpty: false,
      mapCenter: {
        lat: 0,
        lng: 0
      },
      keyword: "",
      langText: {
        search: {
          placeholder: "Apartment Name or Address",
          buttonText: "Search"
        },
        filter: {
          minPrice: "min price",
          maxPrice: "max price",
          beds: "Beds",
          distance: "Distance to school",
          showMap: "Show Map"
        }
      },
      site: "",
      lang: "EN",
      distanceSort: 2,
      map: null,
      markers: [],
      currentItemId: "",
      apartmentAddress: require("../assets/images/luoju-address.png")
    };
  },
  async created() {
    let filterData = this.$localStore.get("filterData") || "";
    if (!filterData) {
      this.saveStoreFilter();
    }
    this.initPageNum();
    this.initLang();
    this.initStoreFilter();
    await this.initPage();

    this.initMap(this.apartmentList[0]);
  },

  async mounted() {
    this.$bus.$on("changeLang", () => {
      this.$route.name;
      if (this.$route.name == "apartmentList") {
        this.initLang();
        this.$go("apartmentList", { page: 1 });
        this.initPage();
      }
    });
    this.$bus.$on("initApartmentList", () => {
      this.initLang();
      this.$go("apartmentList", { page: 1 });
      this.initStoreFilter();
      this.initPage();
    });

    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  },
  methods: {
    initLang() {
      this.initStoreFilter();
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.apartmentList;
      } else if (lang == "CN") {
        this.langText = this.$CN.apartmentList;
      }
      this.lang = lang;
    },
    initPageNum() {
      const currentPage = parseInt(this.$route.query.page) || 1;
      this.page = currentPage;
    },
    clearPrice() {
      this.minPrice = "";
      this.maxPrice = "";
      this.page = 1;
      this.initList();
    },
    distanceSortHandle() {
      this.distanceSort == 1
        ? (this.distanceSort = 2)
        : (this.distanceSort = 1);
      this.saveStoreFilter();
      this.initList();
    },

    initMap(info) {
      this.removeMarkers();

      if (window.google) {
        let lat = 38.84474;
        let lng = -77.05093;
        if (info.lat && info.lnt) {
          lat = info.lat;
          lng = info.lnt;
        }
        this.map = new window.google.maps.Map(
          document.getElementById("map_canvas"),
          {
            center: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            zoom: 16
          }
        );
        this.initMarker();
      }
    },
    removeMarkers() {
      this.markers.forEach(item => {
        item.setMap(null);
      });
      this.markers = [];
    },
    initMarker() {
      let apartMarkers = [];
      this.apartmentList.forEach(item => {
        const data = {
          position: {
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lnt)
          },
          name: item.name
        };
        apartMarkers.push(data);
      });

      apartMarkers.forEach(item => {
        const icon = {
          url: this.apartmentAddress, // url
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(0, 0) // anchor
        };
        const _marker = new MarkerWithLabel({
          position: item.position,
          // clickable: true,
          map: this.map,
          icon,
          labelContent: item.name, // can also be HTMLElement
          labelAnchor: new window.google.maps.Point(0, 30),
          labelClass: "labels", // the CSS class for the label
          labelStyle: { opacity: 1.0 }
        });
        this.markers.push(_marker);
      });
    },
    mouseOver(item) {
      if (item.id == this.currentItemId) {
        return;
      }
      this.currentItemId = item.id;

      this.initMap(item);
    },
    async initPage() {
      await this.initList();
    },
    dateChange() {},
    initStoreFilter() {
      let data = this.$localStore.get("filterData");
      this.keyword = data.keyword;
      this.minPrice = data.minPrice;
      this.maxPrice = data.maxPrice;
      this.currentBeds = data.currentBeds;
      this.distanceSort = data.distanceSort;
      this.unShowMap = data.unShowMap;
      this.site = data.site;

      this.$localStore.set("filterData", data);
    },
    saveStoreFilter() {
      const data = {
        keyword: this.keyword,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        currentBeds: this.currentBeds,
        distanceSort: this.distanceSort,
        site: this.site,
        unShowMap: this.unShowMap
      };
      this.$localStore.set("filterData", data);
    },
    initPageNumAndList() {
      this.page = 1;
      this.saveStoreFilter();
      this.initList();
    },
    search(key) {
      this.keyword = key;
      this.page = 1;
      this.saveStoreFilter();
      this.initList();
    },
    async initList() {
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        bedrooms: this.currentBeds,
        // move_in_date: this.moveDate,
        distance_form_school: this.distanceSort,
        floor_price: this.minPrice,
        top_price: this.maxPrice,
        tag: this.lang == "CN" ? 1 : 2,
        site: this.site
      };
      const resp = await this.$API
        .post("apartmentList", data, false)
        .then(resp => resp);

      if (resp.code == 1000) {
        this.apartmentList = resp.data.list;
        if (this.apartmentList.length == 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
        this.total = resp.data.total;
      }
    },
    unShowMapChange(unShow) {
      this.unShowMap = unShow;
      this.saveStoreFilter();
    },
    handleSizeChange(e) {
      this.page = e;
      this.$localStore.set("page", e);
      this.$go("apartmentList", { page: e });
      this.initPage();
      // this.initList();
    },
    showPrice() {}
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.line {
  margin: 0 6px 0 0;
}
.distance-box {
  height: 40px;
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  border-radius: 4px;
  color: #c0c4cc;
  position: relative;
  .icon-box {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    .distance-icon {
      width: 7px;
    }
    .distance-l-2,
    .distance-r-2 {
      transform: rotate(180deg);
    }
  }
}
.apartment-list-header {
  width: 100%;
  background: #fff;
  min-height: 226px;
  overflow: hidden;
  .search-box {
    width: 564px;
    margin: 60px auto;
    height: 50px;
    border-right: none;
    border-radius: 4px;
  }
  .filter-box-wrap {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
    .filter-box {
      width: 188px;
      margin-right: 20px;
      .close-price {
        position: absolute;
        right: -8px;
        top: 50%;
        transform: translateY(-50%);
        color: #c0c4cc;
      }
    }

    .price-select {
      width: 200px;
      height: 40px;
      box-sizing: border-box;
      padding: 0 20px 0 15px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      color: #c0c4cc;
      .money {
        transform: translate(-1px, -1px);
      }
    }
    .filter-switch {
      margin-left: 20px;
      transform: scale(1.5);
    }
    .filter-right {
      margin-right: 10px;
    }
  }
}
.apartment-content {
  width: 1260px;
  margin: 0 auto;
  max-width: 100%;
  min-height: calc(100vh - 80px - 446px - 226px);
  overflow: hidden;
  box-sizing: border-box;
  transform: translateX(10px);
  padding-left: 20px;
  .show-map-wrap {
    display: flex;
    .content-list {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    .map {
      width: 580px;
      margin-left: 20px;
      margin-top: 30px;
      display: block;
    }
  }

  .content-list {
    width: 100%;

    .content-item {
      margin-right: 28px;
      display: inline-block;
    }
  }
  .map {
    min-height: 1280px;
    width: 0;
    background: #eee;
    border-radius: 6px;
    overflow: hidden;
    // transition: all 0.3s;
    // transform-origin: center;
    display: none;
  }
  .pagination {
    margin: 20px auto 40px;
  }
}
@media screen and (max-width: 767px) {
  .apartment-list-header {
    .search-box {
      width: 90%;
      margin: 20px auto;
    }
    .filter-box-wrap {
      flex-direction: column;
      .filter-left {
        width: 100%;
        flex-direction: column;
        .filter-box {
          width: 100%;
          margin: 10px 0;
          .close-price {
            right: 8px;
          }
          .price-select {
            width: 100%;
          }
          .filter-item {
            width: 100%;
          }
        }
      }
    }
  }
  .apartment-content {
    max-width: 100%;
    transform: translate(0);
    padding-left: 0;
    .show-map-wrap {
      .apartment-map-wrap {
        display: flex;
        flex-direction: column;
      }
    }

    .content-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-item {
        margin-right: 0;
        width: 90%;
      }
      .poular-item {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
</style>
